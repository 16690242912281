import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapsService } from './maps.service';

@Injectable({
  providedIn: 'root',
})
export class PlacePredictionService {
  constructor(
    private http: HttpClient,
    private maps: MapsService,
  ) {}

  getPlacePredictions(input: string): Promise<string[]> {
    return new Promise((resolve) => {
      const autocompleteService = this.maps.autocompleteService();
      const componentRestrictions: google.maps.places.ComponentRestrictions = {
        country: 'Ar',
      };
      const request: google.maps.places.AutocompletionRequest = {
        input,
        componentRestrictions,
      };
      autocompleteService.getPlacePredictions(
        request,
        (
          result: google.maps.places.AutocompletePrediction[] | null,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (result) {
            const resp: string[] = [];
            for (const e of result) {
              resp.push(e.description);
            }
            resolve(resp);
          }
        },
      );
    });
  }
}
