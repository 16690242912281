<h2 mat-dialog-title class="modal-header">{{ title }}</h2>

<mat-dialog-content>
  <google-map
    *ngIf="mapOptions.center"
    width="100%"
    height="400px"
    [options]="mapOptions"
  >
    <map-marker
      [position]="coordenadas!"
      [options]="markerOptions"
      (mapDragend)="setLocation($event)"
    >
    </map-marker>
  </google-map>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button
    *ngIf="markerOptions.draggable"
    mat-raised-button
    color="primary"
    (click)="guardar()"
  >
    Guardar
  </button>
  <button mat-raised-button type="button" (click)="close()">Volver</button>
</mat-dialog-actions>
