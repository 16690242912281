import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICoordenadas } from 'modelos/src';
import { MapsService } from '../../servicios/maps.service';

@Component({
  selector: 'app-ubicar',
  templateUrl: './ubicar.component.html',
  styleUrls: ['./ubicar.component.scss'],
})
export class UbicarComponent implements OnInit {
  title = 'Ubicación';
  coordenadas?: ICoordenadas;

  public mapOptions: google.maps.MapOptions = MapsService.mapOptions;
  public markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };

  constructor(
    public dialogRef: MatDialogRef<UbicarComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      coordenadas: ICoordenadas;
      title: string;
      markerDraggable: boolean;
    },
  ) {
    this.title = this.data.title || this.title;
    this.mapOptions.center = this.data.coordenadas;
    this.coordenadas = this.data.coordenadas;
    this.markerOptions.draggable = this.data.markerDraggable;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public guardar(): void {
    this.dialogRef.close(this.coordenadas);
  }

  public setLocation(event: google.maps.MapMouseEvent) {
    this.coordenadas = event.latLng?.toJSON();
  }

  ngOnInit(): void {}
}
