import { NgModule } from '@angular/core';
import {
  GoogleMap,
  MapHeatmapLayer,
  MapInfoWindow,
  MapMarker,
  MapPolygon,
  MapCircle,
  MapPolyline,
} from '@angular/google-maps';

//

@NgModule({
  imports: [
    GoogleMap,
    MapMarker,
    MapInfoWindow,
    MapHeatmapLayer,
    MapPolygon,
    MapCircle,
    MapPolyline,
  ],
  exports: [
    GoogleMap,
    MapMarker,
    MapInfoWindow,
    MapHeatmapLayer,
    MapPolygon,
    MapCircle,
    MapPolyline,
  ],
})
export class MapsModule {}
