import { Injectable } from '@angular/core';
import {
  IAlerta,
  IDetalleAlerta,
  ICrearAlertaEstado,
  IListado,
  IQueryParam,
  ICrearAlertaMensaje,
  IAlertaMensaje,
  ICrearAlertaMonitoreo,
  ITopAlertasVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { saveAs } from 'file-saver';
import { JSREPORT } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlertasService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public crear(dato: ICrearAlertaMonitoreo): Observable<IAlerta> {
    return this.http.post(`/alertas`, dato);
  }

  public listar(queryParams?: IQueryParam): Observable<IListado<IAlerta>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/alertas`, { params });
  }

  public getTopByVecino(
    queryParams?: IQueryParam,
  ): Observable<ITopAlertasVecino[]> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/alertas/getTopByVecino`, { params });
  }

  public listarPorId(id: string): Observable<IDetalleAlerta> {
    return this.http.get(`/alertas/${id}`);
  }

  public agregarEstado(
    dato: ICrearAlertaEstado,
    queryParams: IQueryParam,
  ): Observable<IAlerta> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.put(`/alertas/estado`, dato, { params });
  }

  public derivar(
    idAlerta?: string,
    idCentroMonitoreo?: string,
  ): Observable<IAlerta> {
    if (!idAlerta || !idCentroMonitoreo) {
      throw new Error('Faltan datos');
    }

    return this.http.put(
      `/alertas/derivar/${idAlerta}/${idCentroMonitoreo}`,
      {},
    );
  }

  public devolver(idAlerta?: string): Observable<IAlerta> {
    if (!idAlerta) {
      throw new Error('Faltan datos');
    }

    return this.http.put(`/alertas/devolver/${idAlerta}`, {});
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/alertas/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'alertas.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }

  public exportarAlerta(id: string): Promise<void> {
    return new Promise((resolve) => {
      this.http
        .get(`/alertas/exportar/${id}`, { responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'alerta.pdf');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }

  public exportarAudioAlerta(id: string): Promise<void> {
    return this.http.getFile(`/alertas/exportar/mp3/${id}`, 'alerta.mp3');
  }

  public async exportarAudioAlerta2(id: string): Promise<File | null> {
    try {
      return await this.http.getFile2(
        `/alertas/exportar/mp3/${id}`,
        'alerta.mp3',
      );
    } catch (error) {
      return null;
    }
  }

  public resumenAlerta(report: string): Promise<void> {
    return new Promise((resolve) => {
      const body = {
        template: { name: 'resumen-alerta' },
        data: report,
      };
      this.http.postAlertasResumen(JSREPORT, body).subscribe(
        (response: any) => {
          try {
            const file = new File([response], 'alerta.pdf');
            saveAs(file);
            resolve();
          } catch (error) {
            console.error(error);
            resolve();
          }
        },
        (error: any) => {
          console.error(error);
          resolve();
        },
      );
    });
  }

  public resumenAlertaFile(report: string): Promise<File> | null {
    return new Promise((resolve) => {
      const body = {
        template: { name: 'resumen-alerta' },
        data: report,
      };
      this.http.postAlertasResumen(JSREPORT, body).subscribe(
        (response: any) => {
          try {
            const file = new File([response], 'alerta.pdf');
            resolve(file);
            return file;
          } catch (error) {
            console.error(error);
            return null;
          }
        },
        (error: any) => {
          console.error(error);
          return null;
        },
      );
    });
  }

  public crearMensajeAlerta(
    dato: ICrearAlertaMensaje,
  ): Observable<IAlertaMensaje> {
    return this.http.post(`/AlertaMensajes`, dato);
  }

  public listarMensajesAlerta(
    queryParams?: IQueryParam,
  ): Observable<IListado<IAlertaMensaje>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/AlertaMensajes`, { params });
  }
}
