import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  private _size?: typeof google.maps.Size;
  private _point?: typeof google.maps.Point;
  private _polygon?: typeof google.maps.Polygon;
  private _spherical?: typeof google.maps.geometry.spherical;
  private _autocompleteService?: typeof google.maps.places.AutocompleteService;

  // Opciones de mapa

  public static readonly mapOptions: google.maps.MapOptions = {
    zoom: 13,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      position: 19,
    },
    zoomControl: true,
    zoomControlOptions: {
      // position: google.maps.ControlPosition.TOP_RIGHT,
      position: 23,
    },
    mapTypeId: 'roadmap',
    mapTypeControl: false,
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      style: 1,
      position: 22,
    },
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    // Zoom manteniendo ctrl
    gestureHandling: 'cooperative',
  };

  public static readonly mapOptionsMarkerPicker: google.maps.MapOptions = {
    mapTypeId: 'satellite',
    scrollwheel: null,
    gestureHandling: 'cooperative',
    zoom: 15,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    zoomControlOptions: {
      position: 23,
    },
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      style: 0,
    },
  };

  public static readonly mapOptionsDrawing: google.maps.MapOptions = {
    zoom: 12,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      position: 19,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: 23,
    },
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      style: 1,
      position: 22,
    },
    // Zoom manteniendo ctrl
    gestureHandling: 'cooperative',
  };

  public static readonly drawingManagerOptions: google.maps.drawing.DrawingManagerOptions =
    {
      polygonOptions: {
        editable: true,
        draggable: true,
      },

      drawingMode: 'polygon' as any,
      drawingControl: true,
      drawingControlOptions: {
        position: 21,
        drawingModes: ['polygon'] as any,
      },
    };

  constructor() {
    console.log('MapsService');
    this.loadAll();
  }

  // Carga de librerias

  private async loadAll() {
    await Promise.all([
      this.loadCore(),
      this.loadMaps(),
      this.loadGeometry(),
      this.loadAutocomplete(),
    ]);
    console.log('All libraries loaded');
  }

  private async loadCore() {
    const { Size, Point } = (await google.maps.importLibrary(
      'core',
    )) as google.maps.CoreLibrary;

    this._size = Size;
    this._point = Point;
  }

  private async loadMaps() {
    const { Polygon } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;

    this._polygon = Polygon;
  }

  private async loadGeometry() {
    const { spherical } = (await google.maps.importLibrary(
      'geometry',
    )) as google.maps.GeometryLibrary;

    this._spherical = spherical;
  }

  private async loadAutocomplete() {
    const { AutocompleteService } = (await google.maps.importLibrary(
      'places',
    )) as google.maps.PlacesLibrary;

    this._autocompleteService = AutocompleteService;
  }

  // Funciones Publicas

  public size(width: number, height: number): google.maps.Size {
    return new this._size(width, height);
  }

  public point(x: number, y: number): google.maps.Point {
    return new this._point(x, y);
  }

  public polygon(options: google.maps.PolygonOptions): google.maps.Polygon {
    return new this._polygon(options);
  }

  public computeArea(path: google.maps.MVCArray<google.maps.LatLng>): number {
    return this._spherical.computeArea(path);
  }

  public autocompleteService() {
    return new this._autocompleteService();
  }
}
