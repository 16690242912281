import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UbicarComponent } from './ubicar/ubicar.component';
import { UbicarService } from './ubicar.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoogleMapsModule } from '@angular/google-maps';
import { MaterialModule } from '../material.module';

@NgModule({
    declarations: [
        UbicarComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        GoogleMapsModule,
    ],
    providers: [
        UbicarService
    ]
})

export class UbicarModule { }
